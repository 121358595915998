<template>
      <v-card
      :loading="loading_custom_script"
        >
        <v-card-title>Custom: Certificates, Passports & Photos</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="['EU', 'US']"
                v-model="warehouse"
                outlined
                label="Get certificates & passports from"
                />
              </v-col>
              <v-col cols="4" class="d-flex">
                <v-select
                  :items="['All', 'Specific']"
                  v-model="scope"
                  outlined
                  label="Fetch"
                  />
                <v-text-field
                  v-if="scope === 'Specific'"
                  outlined
                  v-model="order_number"
                  label="Order number"
                  prepend-inner-icon="mdi-pound"
                  />
              </v-col>
              <v-col cols="2">
                <v-btn 
                  @click="custom_avatar"
                  :loading="this.loading_custom_script"
                  color="secondary"
                  >{{ is_loaded ? 'Refresh' : 'Load...'}}</v-btn>
            </v-col>
            <template v-if="custom_scripts.certificates && custom_scripts.certificates.length">
              <v-col cols="12"
                >
                <v-select
                  :items="custom_scripts.certificates"
                  :item-text="item => `${item.shopify_name}: ${item.name}`"
                  item-value="order_id"
                  multiple
                  chips
                  class="align-self-center"
                  style=""
                  label="Certificates"
                  :messages="`${selected_certificates.length} selected`"
                  v-model="selected_certificates"
                  outlined
                  />
              </v-col>
              <v-col cols="6">
                  <v-btn
                    class="mr-8"
                    color="primary"
                    :loading="loading.certificates"
                    :disabled="!printserver_connected"
                    @click="handle({
                      type: 'certificates',
                      method: 'print',
                    })"
                  >
                    Send to printserver ({{selected_certificates.length}})
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                  :loading="loading.certificates"
                  color="primary"
                    @click="handle({
                      type: 'certificates',
                      method: 'open',
                    })"
                  >
                    Open ({{selected_certificates.length}})
                  </v-btn>
              </v-col>
            </template>
            <template v-if="custom_scripts.passports && custom_scripts.passports.length">
            <v-col cols="12"
              >
              <v-select
                :items="custom_scripts.passports"
                :item-text="item => `${item.shopify_name}: ${item.name}`"
                item-value="order_id"
                multiple
                chips
                class=""
                style=""
                label="Passports"
                :messages="`${selected_passports.length} selected / ${Math.ceil(selected_passports.length / 6)} papers`"
                v-model="selected_passports"
                outlined
                />
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="mr-8"
                  color="primary"
                  :loading="loading.passports"
                  :disabled="!printserver_connected"
                  @click="handle({
                    type: 'passports',
                    method: 'print',
                  })"
                >
                  Send to printserver ({{Math.ceil(selected_passports.length / 6)}} papers)
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                :loading="loading.passports"
                color="primary"
                  @click="handle({
                    type: 'passports',
                    method: 'open',
                  })"
                >
                  Open ({{Math.ceil(selected_passports.length / 6)}} papers)
                </v-btn>
            </v-col>
            </template>
            <template v-if="filtered_photos && filtered_photos.length">
            <v-col cols="12"
              >
              <v-select
                :items="filtered_photos"
                :item-text="item => `${item.shopify_name}${item.index && `-${item.index + 1}` || ''}: ${item.name}${item.printed && ' (printed)' || ''}`"
                return-object
                multiple
                chips
                class=""
                style=""
                label="Photos"
                :messages="`${selected_photos.length} selected`"
                v-model="selected_photos"
                outlined
                >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle_photos(Infinity)"
                  >
                    <v-list-item-action>
                      <v-icon :color="selected_photos.length > 0 ? 'indigo darken-4' : ''">
                        {{ selected_photos.length && 'mdi-close-box' || 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle_photos(10)"
                  >
                    <v-list-item-action>
                      <v-icon :color="selected_photos.length > 0 ? 'indigo darken-4' : ''">
                        {{ selected_photos.length && 'mdi-close-box' || 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select 10
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle_photos(20)"
                  >
                    <v-list-item-action>
                      <v-icon :color="selected_photos.length > 0 ? 'indigo darken-4' : ''">
                        {{ selected_photos.length && 'mdi-close-box' || 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select 20
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              </v-col>
              <v-col cols="6">
                <v-btn
                :loading="loading.photos"
                color="primary"
                  @click="handle({
                    type: 'photos',
                    method: 'open',
                  })"
                >
                  Inspect ({{Math.ceil(selected_photos.length)}})
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn
                :loading="loading.photos"
                color="primary"
                  @click="mark_printed"
                >
                  Mark printed
                </v-btn>
            </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="custom_scripts.cert_prints"
            :disabled="!custom_scripts.cert_prints.length"
            color="primary"
            @click="print_certificates"
            :loading="loading_print_certificates"
            >Print {{ custom_scripts.cert_prints.length }} certificates</v-btn>
          <v-btn
            v-if="custom_scripts.passport_prints"
            :disabled="!custom_scripts.passport_prints.length"
            color="primary"
            >Print {{ custom_scripts.passport_prints.length }} passport pages</v-btn>
        </v-card-actions>
        <v-dialog
            v-model="photos_dialog"
            scrollable
            max-width="600px"
            >
            <v-card>
              <v-card-title>
                Photos
              </v-card-title>
              <v-card-text>
                  <div class="grid">
                    <div
                      v-for="(print, index) in prints"
                      :key="index"
                      class="pa-2 ma-2 item rounded"
                      >
                      <p>{{ print.shopify_name }}</p>
                      <v-switch
                        v-model="print.crop"
                        label="Crop"/>
                      <v-img
                        :src="print.url"
                        :width="print.is_landscape ? 500 : 400"
                        :height="print.is_landscape ? 400 : 500"
                        class="grey lighten-3"
                        :contain="!print.crop"
                        />
                    </div>
                  </div>

              </v-card-text>
              <v-card-actions>
                <v-btn 
                  color="primary" 
                  @click="onCreatePdf"
                  :loading="loading.photos"
                  >Create pdf</v-btn>
                <v-btn
                :loading="loading.photos"
                color="primary"
                  @click="mark_printed"
                >
                  Mark printed
                </v-btn>
              </v-card-actions>
              </v-card>
        </v-dialog>

      </v-card>
</template>

<script>

import { mapState } from 'vuex'
import { PDFDocument, degrees, StandardFonts, rgb } from 'pdf-lib'

export default {
  props: {

  },
  components: {
    
  },
  data(){
    return {
      loading_custom_script: false,
      loading_print_certificates: false,
      is_loaded: false,
      loading: {
        certificates: false,
        passports: false,
        photos: false,
      },
      warehouse: 'EU',
      scope: 'All',
      order_number: '',
      selected_certificates: [],
      selected_passports: [],
      selected_photos: [],
      photos_dialog: false,
      prints: [],
    }
  },
  computed: {
    ...mapState({ 
      custom_scripts: state => state.custom_scripts,
      printserver_connected: state => state.warehouse.printserver_connected
    }),
    filtered_photos(){
      return this.custom_scripts.photos?.reduce((tot, order) => 
        tot.concat(order.photo_ids.map((id, index) => ({ ...order, photo_id: id, index }))),[]
      )
      .filter(i => !i.printed)
    }
  },
  methods: {
    toggle_photos (amount) {
        this.$nextTick(() => {
          if (this.selected_photos.length) {
            this.selected_photos = []
          } else {
            this.selected_photos = this.filtered_photos.slice(0, amount)
          }
        })
      },
    async mark_printed (){
      this.loading.photos = true
      await this.$store.dispatch('custom_scripts/set_printed', { ids: this.selected_photos.map(i => `${i.order_id}:${i.photo_id}`) })
      await this.$store.dispatch('custom_scripts/get_ac_items', { warehouse: this.warehouse })
      this.selected_photos = []
      this.photos_dialog = false
      this.loading.photos = false
    },
    async handle({ type, method }){
      this.loading_custom_script = true
      this.loading[type] = true
      this.prints = await this.$store.dispatch('custom_scripts/handle_ac', { type, method, ids: this[`selected_${type}`] })
      if(type === 'photos') this.photos_dialog = true
      this.loading[type] = false
      this.loading_custom_script = false
    },
    async custom_avatar(){
      this.loading_custom_script = true
      await this.$store.dispatch('custom_scripts/get_ac_items', { warehouse: this.warehouse, order_number: this.scope === 'Specific' && this.order_number })
      if(!this.custom_scripts.certificates?.length && !this.custom_scripts.passports?.length) this.$store.commit('app/SET_SNACK_BAR', 'No prints!')
      else {
        this.selected_certificates = this.custom_scripts.certificates.map(c => c.order_id)
        this.selected_passports = this.custom_scripts.passports.map(c => c.order_id)
        }
        this.is_loaded = true
      this.loading_custom_script = false
    },
    async onCreatePdf(){
      this.loading.photos = true
      const pdfDoc = await PDFDocument.create()
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      const actions = this.prints.map(async print => {
        const image = await pdfDoc.embedJpg(print.arraybuffer)
        const { width, height } = image.size()
        const is_landscape = width > height
        const [page_width, page_height] = is_landscape ? [2.834646 * 257, 2.834646 * 207] : [2.834646 * 207,2.834646 * 257] // [71.98 * 10, 71.98*8] : [71.98 * 8, 71.98*10]

        // 207 , 257
        
        const size = print.crop ? 
          [{ width: page_width, height: height / (width / page_width) },
          { width: width / (height / page_height), height: page_height }].find(c => c.width >= page_width && c.height >= page_height)
          : image.scaleToFit(page_width, page_height)
        // const size = is_landscape ? image.scaleToFit(page_width, page_height) : 
        //   { height: page_width, width:  }
        
        const page = pdfDoc.addPage([page_width, page_height])
        page.drawImage(image, {
          x: (page_width - size.width) / 2,
          y: (page_height - size.height) / 2,
          ...size,
        })
        if(page_height > page_width) page.setRotation(degrees(90))
        page.drawText(print.shopify_name, {
          x: page.getWidth() - 35,
          y: 6,
          size: 8,
          color: rgb(.5,.5,.5),
          font: helveticaFont,
          // rotate: degrees(90)
        })
      })
      await Promise.all(actions)
      const d = await pdfDoc.save()
      const file = new Blob([d.buffer], {type: 'application/pdf'})
      const url = URL.createObjectURL(file)
      window.open(url)
      this.loading.photos = false
    }
  },
  mounted(){
    
  }
}
</script>
<style lang="scss" scoped>
  // .grid {
  //   margin: 20px 0;
  //   display: flex;
  //   gap: 20px;
  //   overflow-x: auto;
  //   scroll-snap-type: x mandatory;
  // }
  
  .item {
    // scroll-snap-align: start;
    // flex: 0 0 500px;
    // display: flex;
    border: 1px solid black;
  }
</style>